
//these are enqueued from a cdn in functions, except for split text which is part of club greensock
// import { gsap } from "./vendor/gsap";
// import { ScrollTrigger } from "./vendor/gsap/ScrollTrigger";
import { SplitText } from "./vendor/gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, SplitText);


const headings = document.querySelectorAll(".skew");
const fadesTop = document.querySelectorAll(".fadein-top, .wp-block-column");
const fadesBottom = document.querySelectorAll(".fadein-bottom");
const fadesLeft = document.querySelectorAll(".fadein-left, .intro-overlay-container");
const portrait = document.querySelectorAll(".portrait-wrapper");
const fadesRight = document.querySelectorAll(".fadein-right");
const fadeIn = document.querySelectorAll(".fadein, h1, h3, .wp-block-image:not(.fade2)");
const fadeIn2 = document.querySelectorAll(".fade2");
const separators = document.querySelectorAll("hr");
const wiggles = document.querySelectorAll(".intro-overlay span");

function setupSplits() {

  headings.forEach(heading => {
    // Reset if needed
    if (heading.anim) {
      heading.anim.progress(1).kill();
      heading.split.revert();
    }

    const split = new SplitText(heading, {
      type: "lines,words,chars",
      linesClass: "split-line"
    });
    // Set up the anim
    gsap.from(split.lines, {
      scrollTrigger: {
        toggleActions: "restart pause resume reverse",
        trigger: heading,
        start: 'top 100%',
      },
      duration: 1,
      yPercent: 100,
      ease: 'power2.out',
      skewY: .1,
      skewX: 0,
      rotationX:180,
      stagger: 0.1,
      autoAlpha: 0,
      delay: .2
    })
    gsap.to(heading, {
      yPercent: 0,
      skewY: 0,
      skewX:0,
      rotationX:0,
      ease: 'circ.out',
      duration:1,
      transformOrigin: "0% 100%",
      autoAlpha: 1
    });
  });

    wiggles.forEach(wiggle => {
        if (wiggle.anim) {
            wiggle.anim.progress(1).kill();
            wiggle.split.revert();
        }
        const tl = gsap.timeline({
            repeat: -1,
            delay: 8,
        });

        tl.to(wiggle, {
            x: 30,
            ease: "power1.inOut",
            duration: .7
        });
        tl.to(wiggle, {
            x: 0,
            ease: "power1.inOut",
            duration: .7
        });
    });

  fadesTop.forEach(fade => {
    if (fade.anim) {
      fade.anim.progress(1).kill();
      fade.split.revert();
    }
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: fade,
        start: "top 100%",
        toggleActions: "restart pause resume reverse",
      }
    });

    tl.fromTo(fade, {
      opacity: 0,
      y: -50,
      duration: 1
    }, {
      opacity: 1,
      duration: 1,
      autoAlpha: 1,
      y: 0,
      delay:.3
    });
  });

  fadesBottom.forEach(fade => {
    if (fade.anim) {
      fade.anim.progress(1).kill();
      fade.split.revert();
    }
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: fade,
        start: "top 100%",
        toggleActions: "restart pause resume reverse",
      }
    });

    tl.fromTo(fade, {
      opacity: 0,
      y: 100,
    }, {
      opacity: 1,
      duration: 1,
      autoAlpha: 1,
      y: 0,
      delay: 0.5
    });
  });

  fadesLeft.forEach(fade => {
    if (fade.anim) {
      fade.anim.progress(1).kill();
      fade.split.revert();
    }
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: fade,
      }
    });

    tl.fromTo(fade, {
      opacity: 0,
      x: -200,
    }, {
      opacity: 1,
      duration: 1,
      autoAlpha: 1,
      x: 0,
      delay: 0.5
    });
  });

  portrait.forEach(fade => {
    if (fade.anim) {
      fade.anim.progress(1).kill();
      fade.split.revert();
    }
    const tl = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: fade,
    //     start: "top",
    //     toggleActions: "restart pause resume reverse",
    //   }
    });

    tl.fromTo(fade, {
      opacity: 0,
      x: 100,
    }, {
      opacity: 1,
      duration: 1,
      autoAlpha: 1,
      x: 0,
      delay: 0.5
    });
  });


  fadesRight.forEach(fade => {
    if (fade.anim) {
      fade.anim.progress(1).kill();
      fade.split.revert();
    }
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: fade,
        start: "top 100%",
        toggleActions: "restart pause resume reverse",
      }
    });

    tl.fromTo(fade, {
      opacity: 0,
      x: -100,
    }, {
      opacity: 1,
      duration: 1,
      autoAlpha: 1,
      x: 0,
      delay: 0.5
    });
  });

  fadeIn.forEach(fadein => {
    if (fadein.anim) {
      fadein.anim.progress(1).kill();
      fadein.split.revert();
    }
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: fadein,
        start: "top 100%",
        toggleActions: "restart pause resume reverse",
      }
    });

    tl.fromTo(fadein, {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 1,
      autoAlpha: 1,
      delay: 0.5
    });
  });

    fadeIn2.forEach(fadein => {
        if (fadein.anim) {
            fadein.anim.progress(1).kill();
            fadein.split.revert();
        }
        const tl = gsap.timeline({
        scrollTrigger: {
            trigger: fadein,
            start: "top center",
            toggleActions: "restart pause resume reverse",
        }
        });

        tl.fromTo(fadein, {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
            autoAlpha: 1,
            delay: 0.5
        });
    });

  separators.forEach(separator => {
    if (separator.anim) {
      separator.anim.progress(1).kill();
      separator.split.revert();
    }
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: separator,
        start: "top bottom",
        toggleActions: "restart pause resume reverse",
      }
    });

    tl.fromTo(separator, {
      width: 0,
      duration: 1
    }, {
      duration: 1,
      autoAlpha: 1,
      width: '100%'
    });
  });

  // gsap.to(".img-wrapper > img", {
  //   y: -100,
  //   duration: 1,
  //   ease: "none",
  //   scrollTrigger: {
  //     trigger: ".img-wrapper > img",
  //     // start: "top bottom", // the default values
  //     // end: "bottom top",
  //     scrub: true
  //   },
  // });
}

ScrollTrigger.addEventListener("scroll", setupSplits);
setupSplits();




//hovery images
// const pressImage = document.querySelectorAll(".press .img-wrapper");
// const pos = {
//   x: window.innerWidth / 2,
//   y: window.innerHeight / 2
// };
// const mouse = {
//   x: pos.x,
//   y: pos.y
// };
// const speed = 0.35;

// const xSet = gsap.quickSetter(pressImage, "x", "px");
// const ySet = gsap.quickSetter(pressImage, "y", "px");

// window.addEventListener("mousemove", e => {
//   mouse.x = e.x / 6;
//   mouse.y = e.y / 10;
// });

// gsap.ticker.add(() => {

//   // adjust speed for higher refresh monitors
//   const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

//   pos.x += (mouse.x - pos.x) * dt;
//   pos.y += (mouse.y - pos.y) * dt;
//   xSet(pos.x);
//   ySet(pos.y);
// });
