console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://monk.com.au ', 'padding: 5px 13px;');

import './skip-link-focus-fix';
import './smoothscroll';
import './navigation';
import './scrollyclasses';
import './viewport-height';
import './fw-video';
import './sliders';
import './accordions';
import './fancybox';
import './gsapanims';

jQuery(function ($) {

	//page
	var $hamburger = $(".hamburger"),
		$site = $("body"),
		$menu = $(".main-navigation"),
		$menuitems = $(".menu-item"),
		$screenOverlay = $(".screen-overlay"),
        $introOverlay = $(".intro-overlay"),
        $introScreen = $(".intro-overlay-container");

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	$hamburger.on("click", function () {
		$hamburger.toggleClass("is-active");
		$site.toggleClass("menu-open");
		// if ( $('body').hasClass('menu-open') ) {
		// 	$('#mobile-menu .label').text('Close')
		// } else {
		// 	$('#mobile-menu .label').text('Menu')
		// }
	});

	//close menu with an outside click (basically anywhere on .site-content)
	function closeMenu() {
		$site.removeClass("menu-open");
		$menu.removeClass("toggled");
		$menuitems.removeClass('toggled-on');
		$hamburger.removeClass("is-active");
	}

    function drawImg() {
        if ( $site.hasClass('home') ) {
            var paths = document.querySelectorAll('.home-meg path');
            var i = 0;

            for (i = 0; i < paths.length; i++) {
                let lineLength = paths[i].getTotalLength();
                let path = paths[i];
                setTimeout( function() {
                    path.style.strokeDasharray = lineLength;
                    path.style.strokeDashoffset = lineLength;
                    path.classList.add('animate-in');
                }, 600*i );
            }
        }
    }

	$screenOverlay.on('click', closeMenu);

	$(document).on('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});

    $introScreen.on('click', function() {
        if (!$introOverlay.hasClass('activate')) {
            $introOverlay.addClass('activate');
            drawImg();
            $site.removeClass('intro-open');

            setTimeout( function() {
                $introOverlay.addClass('kill');
            }, 2000 );
        }
    });

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });
	// AOS.init();

    $(document).ready(function() {
        var $element = $('.header-bg'),
            $portrait = $('.portrait-wrapper img'),
            $currentIndex = 0,
            $totalPortrait = $portrait.length;

        if ($site.hasClass('page') || $site.hasClass('page-blog')) {
            $(window).on('scroll', function() {
                var scrollTop = $(window).scrollTop();
                var elementOffset = $element.offset().top;
    
                if (scrollTop >= elementOffset) {
                    $site.addClass('header-act');
                } else {
                    $site.removeClass('header-act');
                }
            });
        }

        function togglePortrait() {
            $portrait.removeClass('blink');
            $portrait.eq($currentIndex).addClass('blink');
            $currentIndex = ($currentIndex + 1) % $totalPortrait;
        }

        if ( $totalPortrait > 1 ) {
            togglePortrait();
            setInterval(togglePortrait, 800);
        }

        if ( $site.hasClass('meg-visit') ) {
            drawImg();
        } else if (!$site.hasClass('meg-visit') && $site.hasClass('home')) {
            $site.addClass('intro-open');
        }

    });


});